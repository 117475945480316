export class DateUtils {
  static convertUTCToLocalDate(utcDate: string): Date {
    const date = new Date(utcDate);
    const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    return localDate;
  }
  static convertLocalToUTC(localDate: Date): string {
    return localDate.toISOString();
  }

  static toLocalISOString(date: Date): string {
    const offset = date.getTimezoneOffset();
    const localDate = new Date(date.getTime() - (offset * 60000));
    return localDate.toISOString().slice(0, 16); // Format YYYY-MM-DDTHH:mm
  }

}
