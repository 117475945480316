import {Component, Input, OnInit} from '@angular/core';
import {NavController, PopoverController} from "@ionic/angular";
import {ForumService} from "../../forum/forum.service";
import {NotificationPopoverService} from "./notification-popover.service";

@Component({
             selector: 'app-notification-popover',
             templateUrl: './notification-popover.page.html',
             styleUrls: ['./notification-popover.page.scss'],
           })
export class NotificationPopoverPage implements OnInit {

  @Input() notifications: any[] = [];
  @Input() markAsRead: (id: number) => void;
  isLoading: boolean = true;

  constructor(private navController: NavController,
              private forumService: ForumService,
              private popoverController: PopoverController,
              private notificationPopoverService: NotificationPopoverService) {
  }

  async onNotificationClick(notification: any) {
    this.markAsRead(notification.id);
    if (notification.type == 'expiring_soon' || notification.type == 'expired') {
      await this.navController.navigateForward('/payment');
    } else if (notification.type == 'renew-subscription') {
      await this.navController.navigateForward('/license');
    }
    else if (notification.type == 'forum_topic') {
      const topic = await this.forumService.getTopicById(notification.topic_id)
      if (topic) {
        await this.navController.navigateForward(`/forum/${notification.topic_id}`);
      }
    }
    this.popoverController.dismiss();
  }

  async ngOnInit() {
    await this.replaceUserIdsWithNames();
    this.isLoading = false;
  }
  async replaceUserIdsWithNames() {
    for (const notification of this.notifications) {
      notification.message = await this.replaceUserIdWithName(notification.message);
    }
  }

  async replaceUserIdWithName(message: string): Promise<string> {
    const regex = /<uerId>(.*?)<\/uerId>/g;
    let match;
    while ((match = regex.exec(message)) !== null) {
      const userId = match[1];
      try {
        const userDetails = await this.notificationPopoverService.getUserDetails(userId);
        const fullName = userDetails.client_first_name && userDetails.client_last_name
          ? `${userDetails.client_first_name} ${userDetails.client_last_name}`
          : `${userDetails.professional_first_name} ${userDetails.professional_last_name}`;
        message = message.replace(match[0], fullName);
      } catch (error) {
        console.error('Failed to replace user ID with name:', error);
      }
    }
    return message;
  }
}
