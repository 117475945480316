import {Injectable} from '@angular/core';
import {SupabaseClient} from '@supabase/supabase-js';
import {Subject} from 'rxjs';
import {AuthService} from '../auth/auth.service';
import {SupabaseService} from '../utils/supabase.service';
import {ToastController} from '@ionic/angular';

@Injectable({
              providedIn: 'root',
            })
export class NotificationService {
  private supabaseClient: SupabaseClient;
  private notificationSource = new Subject<any>();
  notifications$ = this.notificationSource.asObservable();

  constructor(
    private authService: AuthService,
    private supabaseService: SupabaseService,
    private toastController: ToastController
  ) {
    this.supabaseClient = this.supabaseService.getSupabaseClient();
    this.authService.getCurrentUser()
        .subscribe((user: any) => {
          if (user) {
            this.listenToNotifications(user.id);
          }
        });
  }


  private listenToNotifications(userId: string) {
    const audio = new Audio('assets/sounds/notification.mp3');
    this.supabaseClient
        .channel('public:notifications')
        .on(
          'postgres_changes',
          { event: 'INSERT', schema: 'public', table: 'notifications', filter: `user_id=eq.${userId}` },
          payload => {
            this.notificationSource.next(payload.new);
            audio.play();
          }
        )
        .subscribe((status) => {
        });
  }

  async getUserNotifications() {
    const { data, error } = await this.supabaseClient
                                      .from('notifications')
                                      .select('*')
                                      .eq('user_id', this.authService.getUserId())
                                      .order('created_at', { ascending: false })
                                      .limit(20);

    if (error) {
      console.error('Erreur lors de la récupération des notifications:', error);
      return [];
    }

    return data;
  }

  async addNotification(userId: string, type: string, message: string, topicId: string) {
    const { data, error } = await this.supabaseClient
                                      .from('notifications')
                                      .insert([
                                                {
                                                  user_id: userId,
                                                  type,
                                                  message,
                                                  topic_id: topicId,
                                                  is_read: false,
                                                },
                                              ]);

    if (error) {
      throw new Error(error.message);
    }

    return data;
  }

  async markAsRead(notificationId: number): Promise<void> {
    const { error } = await this.supabaseClient
                                .from('notifications')
                                .update({ is_read: true })
                                .eq('id', notificationId);

    if (error) {
      throw new Error(error.message);
    }
  }

  async presentToast(message: string, color: 'success' | 'danger') {
    const toast = await this.toastController.create({
                                                      message,
                                                      duration: 3000,
                                                      color,
                                                      position: 'top',
                                                    });
    await toast.present();
  }
}
