import {AuthGuard} from './auth/guards/auth.guard';
import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {ProfessionalGuard} from "./calendar/professional/guards/professional.guard";
import {ClientGuard} from "./calendar/client/guards/client.guard";
import {CookieConsentGuard} from "./cookie-consent/cookie-consent.guard";
import {AdminGuard} from "./article/guard/admin.guard";

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./auth/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./auth/register/register.module').then((m) => m.RegisterModule),
  },
  {
    path: 'annonces-list',
    loadChildren: () => import('./annonce/annonces-list/annonces-list.module').then((m) => m.AnnoncesListPageModule),
    canActivate:[AuthGuard, CookieConsentGuard]
  },
  {
    path: 'annonce-details/:id',
    loadChildren: () => import('./annonce/annonce-details/annonce-details.module').then((m) => m.AnnonceDetailsModule),
    canActivate:[AuthGuard, CookieConsentGuard]
  },
  {
    path: 'annonce-create-update',
    loadChildren: () => import('./annonce/annonce-create-update/annonce-create-update.page.module').then((m) => m.AnnonceCreateUpdatePageModule),
    canActivate:[AuthGuard, ProfessionalGuard, CookieConsentGuard]
  },
  {
    path: 'annonce-create-update/:id',
    loadChildren: () => import('./annonce/annonce-create-update/annonce-create-update.page.module').then((m) => m.AnnonceCreateUpdatePageModule),
    canActivate:[AuthGuard, ProfessionalGuard, CookieConsentGuard]
  },
  {
    path: 'professional-calendar/:id',
    loadChildren: () => import('./calendar/professional/calendar-component.module').then((m) => m.CalendarComponentModule),
    canActivate:[AuthGuard, ProfessionalGuard, CookieConsentGuard]
  },
  {
    path: 'client-calendar/:id',
    loadChildren: () => import('./calendar/client/calendar-component.module').then((m) => m.CalendarComponentModule),
    canActivate:[AuthGuard, ClientGuard, CookieConsentGuard]
  },
  {
    path: 'evaluation',
    loadChildren: () => import('./evaluation/evaluation.module').then((m) => m.EvaluationModule),
    canActivate:[AuthGuard, ClientGuard, CookieConsentGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
    canActivate:[AuthGuard, CookieConsentGuard]
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then((m) => m.AboutModule),
    canActivate:[AuthGuard, CookieConsentGuard]
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./auth/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'forum',
    loadChildren: () => import('./forum/forum.module').then( m => m.ForumPageModule),
    canActivate:[AuthGuard, CookieConsentGuard]
  },
  {
    path: 'forum/:id',
    loadChildren: () => import('./forum/forum.module').then( m => m.ForumPageModule),
    canActivate:[AuthGuard, CookieConsentGuard]
  },
  {
    path: 'income',
    loadChildren: () => import('./income/income.module').then( m => m.IncomePageModule),
    canActivate:[AuthGuard, ProfessionalGuard, CookieConsentGuard]
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then( m => m.PaymentPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'index',
    loadChildren: () => import('./index/index.module').then( m => m.IndexPageModule),
    canActivate:[AuthGuard, CookieConsentGuard]
  },
  {
    path: 'license',
    loadChildren: () => import('./license/license.module').then( m => m.LicensePageModule),
    canActivate:[AuthGuard, ProfessionalGuard, CookieConsentGuard]
  },
  {
    path: 'notification-popover',
    loadChildren: () => import('./utils/notification-popover/notification-popover.module').then( m => m.NotificationPopoverPageModule),
    canActivate:[AuthGuard, CookieConsentGuard]
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./contact-us/contact-us.module').then( m => m.ContactUsPageModule),
    canActivate:[AuthGuard, CookieConsentGuard]
  },
  {
    path: 'legal-mention',
    loadChildren: () => import('./legal-mention/legal-mention.module').then( m => m.LegalMentionPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'cookie-consent',
    loadChildren: () => import('./cookie-consent/cookie-consent.module').then( m => m.CookieConsentPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'article-list',
    loadChildren: () => import('./article/article-list/article-list.module').then( m => m.ArticleListPageModule),
    canActivate:[AuthGuard, AdminGuard]
  },
  {
    path: 'article-create-update',
    loadChildren: () => import('./article/article-create-update/article-create-update.module').then( m => m.ArticleCreateUpdatePageModule),
    canActivate:[AuthGuard, AdminGuard]
  },
  {
    path: 'article-create-update/:id',
    loadChildren: () => import('./article/article-create-update/article-create-update.module').then(m => m.ArticleCreateUpdatePageModule),
    canActivate:[AuthGuard, AdminGuard]
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => import('./terms-and-conditions/terms-and-conditions.module').then( m => m.TermsAndConditionsPageModule)
  }
];

@NgModule({
            imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
            exports: [RouterModule],
          })
export class AppRoutingModule {
}
