import {Injectable} from '@angular/core';
import {SupabaseClient} from '@supabase/supabase-js';
import {SupabaseService} from "../supabase.service";


@Injectable({
              providedIn: 'root',
            })
export class NotificationPopoverService {
  private supabaseClient: SupabaseClient;

  constructor(private supabaseService: SupabaseService) {
    this.supabaseClient = this.supabaseService.getSupabaseClient();
  }

  async getUserDetails(userId: string): Promise<any> {
    const { data, error } = await this.supabaseClient
      .from('user_details')
      .select('client_first_name, client_last_name, professional_first_name, professional_last_name')
      .eq('id', userId)
      .single();

    if (error) {
      console.error('Error fetching user details:', error);
      throw error;
    }

    return data;
  }

}
