import {AuthService} from 'src/app/auth/auth.service';
import {Router} from '@angular/router';
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {App, URLOpenListenerEvent} from '@capacitor/app';
import {MenuController, PopoverController} from "@ionic/angular";
import {NotificationService} from "./utils/notification.service";
import {NotificationPopoverPage} from "./utils/notification-popover/notification-popover.page";
import {CookieConsentService} from "./cookie-consent/cookie-consent.service";

@Component({
             selector: 'app-root',
             templateUrl: 'app.component.html',
             styleUrls: ['app.component.scss'],
           })
export class AppComponent implements OnInit {

  optionalUser = this.authService.getOptionalCurrentUser();
  notifications: any[] = [];
  unreadCount: number = 0;
  cookieConsent: string;
  isUserConnected = false;

  constructor(
    private popoverController: PopoverController,
    private router: Router,
    private authService: AuthService,
    private menuController: MenuController,
    private notificationService: NotificationService,
    private cookieConsentService: CookieConsentService,
    private cdr: ChangeDetectorRef
  ) {
    this.setupListener();
  }

  ngOnInit(): void {
    this.cookieConsentService.cookieConsent$.subscribe((consent) => {
      this.cookieConsent = consent;
    });
    this.optionalUser.subscribe((user: any) => {
      if (user) {
        this.isUserConnected = true;
        this.loadNotifications();
        this.notificationService.notifications$.subscribe(notification => {
          this.notifications.unshift(notification);
          this.unreadCount++;
          this.cdr.detectChanges();
        });
      } else {
        this.isUserConnected = false;
      }
    })
  }

  async loadNotifications() {
    this.notifications = await this.notificationService.getUserNotifications();
    this.unreadCount = this.notifications.filter(n => !n.is_read).length;
  }

  async presentNotificationPopover(event: Event) {
    const popover = await this.popoverController.create({
                                                          component: NotificationPopoverPage,
                                                          componentProps: {
                                                            notifications: this.notifications,
                                                            markAsRead: (id: number) => this.markAsRead(id)
                                                          },
                                                          event: event,
                                                          translucent: true,
                                                          cssClass: 'notification-popover',
                                                          animated: true,
                                                          backdropDismiss: true
                                                        });
    await popover.present();
  }

  async markAsRead(notificationId: number) {
    await this.notificationService.markAsRead(notificationId);
    this.notifications = this.notifications.map(notification =>
                                                  notification.id === notificationId ? { ...notification, is_read: true } : notification
    );
    this.unreadCount = this.notifications.filter(n => !n.is_read).length;
  }

  setupListener() {
    App.addListener('appUrlOpen', async (data: URLOpenListenerEvent) => {
      const openUrl = data.url;
      const access = openUrl.split('#access_token=').pop().split('&')[0];
      const refresh = openUrl.split('&refresh_token=').pop().split('&')[0];
      await this.authService.setSession(access, refresh);
    });
  }

  goToHome() {
    this.menuController.close();
    if (this.isUserConnected) {
      this.router.navigateByUrl('/index', {replaceUrl: true});
    } else {
      this.router.navigateByUrl('/', {replaceUrl: true});
    }
  }

  goToAbout() {
    this.router.navigateByUrl('/about', {replaceUrl: true});
    this.menuController.close();
  }

  goToArticle() {
    this.router.navigateByUrl('/article-list', {replaceUrl: true});
    this.menuController.close();
  }

  goToProfile() {
    this.router.navigateByUrl('/profile', {replaceUrl: true});
    this.menuController.close();
  }

  goToAnnonces() {
    this.router.navigateByUrl('/annonces-list', {replaceUrl: true});
    this.menuController.close();
  }

  goToForum() {
    this.router.navigateByUrl('/forum', {replaceUrl: true});
    this.menuController.close();
  }

  goToIncome() {
    this.router.navigateByUrl('/income', {replaceUrl: true});
    this.menuController.close();
  }

  goToLicense() {
    this.router.navigateByUrl('/license', {replaceUrl: true});
    this.menuController.close();
  }

  goToContactUs() {
    this.router.navigateByUrl('/contact-us', {replaceUrl: true});
    this.menuController.close();
  }

  async goToPayment() {
    this.router.navigateByUrl('/payment', {replaceUrl: true});
  }

  async signOut() {
    await this.authService.signOut();
    this.menuController.close();
  }

  isPaymentPage(): boolean {
    return this.router.url === '/payment';
  }

  onMenuClose() {
    this.menuController.close();
  }
}
