import {Injectable} from '@angular/core';
import {SupabaseClient} from '@supabase/supabase-js';
import {Subject} from 'rxjs';
import {AuthService} from '../auth/auth.service';
import {SupabaseService} from "../utils/supabase.service";

@Injectable({
              providedIn: 'root',
            })
export class ForumService {
  private supabaseClient: SupabaseClient;
  private topicActionSource = new Subject<void>();
  topicActionNotification$ = this.topicActionSource.asObservable();

  constructor(private authService: AuthService, private supabaseService: SupabaseService) {
    this.supabaseClient = this.supabaseService.getSupabaseClient();
  }

  async getTopics(page: number, pageSize: number, searchTitleContent = undefined) {
    let topicsQuery = this.supabaseClient
                          .from('topics')
                          .select(`
      id,
      title,
      content,
      created_at,
      user_id,
      user:user_details (
        client_first_name,
        client_last_name,
        professional_first_name,
        professional_last_name
      )
    `);

    if (searchTitleContent && searchTitleContent.trim() !== '') {
      topicsQuery = topicsQuery.ilike('title', `%${searchTitleContent}%`);
    }

    const { data, error } = await topicsQuery
                                      .order('created_at', { ascending: false })
                                      .range((page - 1) * pageSize, page * pageSize - 1);

    if (error) {
      console.error('Erreur lors de la récupération des sujets:', error);
      return [];
    }

    return data;
  }

  async getTopicById(topicId: string) {
    const { data, error } = await this.supabaseClient
                                      .from('topics')
                                      .select(`
      id,
      title,
      content,
      created_at,
      user_id,
      user:user_details (
        client_first_name,
        client_last_name,
        professional_first_name,
        professional_last_name
      )
    `)
                                      .eq('id', topicId);

    if (error) {
      console.error('Erreur lors de la récupération du sujet:', error);
      return null;
    }

    return data;
  }

  async getReplies(topicId: number) {
    const {data, error} = await this.supabaseClient
                                    .from('replies')
                                    .select(`
      id,
      content,
      created_at,
      user_id,
      user:user_details (
        client_first_name,
        client_last_name,
        professional_first_name,
        professional_last_name
      )
    `)
                                    .eq('topic_id', topicId)
                                    .order('created_at', {ascending: true});

    if (error) throw new Error(error.message);
    return data;
  }

  async getInitialReplies(topicId: number, limit: number) {
    const {data, error} = await this.supabaseClient
                                    .from('replies')
                                    .select(`
      id,
      content,
      created_at,
      user_id,
      user:user_details (
        client_first_name,
        client_last_name,
        professional_first_name,
        professional_last_name
      )
    `)
                                    .eq('topic_id', topicId)
                                    .order('created_at', {ascending: false})
                                    .limit(limit);

    if (error) throw new Error(error.message);
    return data.reverse();
  }

  async countTopics(searchTitleContent = undefined) {
    let countTopicsQuery = this.supabaseClient
                               .from('topics')
                               .select('*', {count: 'exact', head: true});

    if (searchTitleContent && searchTitleContent.trim() !== '') {
      countTopicsQuery = countTopicsQuery.ilike('title', `%${searchTitleContent}%`);
    }

    const {count, error} = await countTopicsQuery;

    if (error) {
      console.error('Erreur lors du comptage des sujets:', error);
      return 0;
    }

    return count || 0;
  }

  async countReplies(topicId: number) {
    const {count, error} = await this.supabaseClient
                                     .from('replies')
                                     .select('*', {count: 'exact', head: true})
                                     .eq('topic_id', topicId);

    if (error) {
      console.error('Erreur lors du comptage des réponses:', error);
      return 0;
    }

    return count || 0;
  }

  async addTopic(topic: any) {
    const {data, error} = await this.supabaseClient.from('topics').insert([
                                                                            {
                                                                              user_id: this.authService.getUserId(),
                                                                              title: topic.title,
                                                                              content: topic.content
                                                                            },
                                                                          ])
                                    .select('*')
                                    .single();


    if (error) throw new Error(error.message);
    return data;
  }

  async addReply(topicId: number, content: string) {
    const {data, error} = await this.supabaseClient.from('replies').insert([
                                                                             {
                                                                               topic_id: topicId,
                                                                               user_id: this.authService.getUserId(),
                                                                               content,
                                                                             },
                                                                           ]);

    if (error) throw new Error(error.message);
    return data;
  }

  async updateTopic(topicId: number, updatedTopic: { title: string; content: string }): Promise<void> {
    const { error } = await this.supabaseClient
                                .from('topics')
                                .update(updatedTopic)
                                .eq('id', topicId);

    if (error) {
      throw error;
    }
  }

  async updateReply(replyId: number, content: string): Promise<void> {
    const { error } = await this.supabaseClient
                                .from('replies')
                                .update({ content })
                                .eq('id', replyId);

    if (error) {
      throw error;
    }
  }

  async deleteTopic(topicId: number): Promise<void> {
    const { error } = await this.supabaseClient
                                .from('topics')
                                .delete()
                                .eq('id', topicId);

    if (error) {
      throw error;
    }
  }

  async deleteReply(replyId: number): Promise<void> {
    const { error } = await this.supabaseClient
                                .from('replies')
                                .delete()
                                .eq('id', replyId);

    if (error) {
      throw error;
    }
  }

  topicActionNotification() {
    this.topicActionSource.next();
  }
}
