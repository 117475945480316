import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {SupabaseClient, User} from '@supabase/supabase-js';
import {BehaviorSubject, distinctUntilChanged, Observable, Subject} from 'rxjs';
import {filter, map} from "rxjs/operators";
import {SupabaseService} from "../utils/supabase.service";

@Injectable({
              providedIn: 'root',
            })
export class AuthService {
  private supabase: SupabaseClient;
  private currentUser: BehaviorSubject<User | boolean> = new BehaviorSubject(null);
  private proExpirationNotificationSubject: Subject<boolean> = new Subject<boolean>();
  public proExpirationNotifications$: Observable<boolean> = this.proExpirationNotificationSubject.asObservable();

  constructor(private router: Router, private supabaseService: SupabaseService) {
    this.supabase = this.supabaseService.getSupabaseClient();

    this.supabase.auth.onAuthStateChange((event, sess) => {
      if (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED') {
        this.loadUser().then(() => {
          if (this.isProfessional()) {
            this.isProSubscriptionExpired().then(isExpired => {
              if (isExpired) {
                this.sendSubscriptionExpirationNotification(true);
              } else {
                this.checkAndNotifyExpiredSubscriptions();
              }
            });
          }
        });
      } else if (event === 'SIGNED_OUT') {
        this.currentUser.next(false);
      }
    });
  }

  async loadUser() {
    if (this.currentUser.value) {
      return;
    }
    const user = await this.supabase.auth.getUser();
    if (user.data.user) {
      this.currentUser.next(user.data.user);
    }
    else {
      this.currentUser.next(false);
    }
  }

  async signUpClient(clientData: {
    email: string; password: string; options: {
      data: {
        firstName: string;
        lastName: string;
        gender: string;
        age: number;
        phone: string;
        street: string;
        cityCode: string;
        city: string;
        country: string;
      }
    }
  }) {
    const {email, password, options} = clientData;
    const {data} = options;

    const {data: signUpData, error} = await this.supabase.auth.signUp({
                                                                        email,
                                                                        password,
                                                                        options: {
                                                                          data: {
                                                                            ...data,
                                                                            role: 'client'
                                                                          }
                                                                        }
                                                                      });

    if (error) {
      throw error;
    }

    const user = signUpData.user;
    const clientInsertResponse = await this.supabase.from('client').insert([{
      clt_id: user.id,
      clt_email: email,
      clt_first_name: data.firstName,
      clt_last_name: data.lastName,
      clt_gender: data.gender,
      clt_age: data.age,
      clt_phone: data.phone,
      clt_street: data.street,
      clt_city_code: data.cityCode,
      clt_city: data.city,
      clt_country: data.country,
      clt_created_at: new Date().toISOString()
    }]);

    if (clientInsertResponse.error) {
      throw clientInsertResponse.error;
    }

    return clientInsertResponse;
  }

  async signUpProfessional(professionalData: {
    email: string; password: string; options: {
      data: {
        firstName: string;
        lastName: string;
        gender: string;
        age: number;
        phone: string;
        street: string;
        cityCode: string;
        city: string;
        country: string;
      }
    }
  }) {
    const {email, password, options} = professionalData;
    const {data} = options;

    const {data: signUpData, error} = await this.supabase.auth.signUp({
                                                                        email,
                                                                        password,
                                                                        options: {
                                                                          data: {
                                                                            ...data,
                                                                            role: 'professional'
                                                                          }
                                                                        }
                                                                      });

    if (error) {
      throw error;
    }

    const user = signUpData.user;
    const pro_start_subscription = new Date();
    const pro_end_subscription = new Date(pro_start_subscription);
    pro_end_subscription.setMonth(pro_end_subscription.getMonth() + 6);
    pro_end_subscription.setDate(pro_end_subscription.getDate() + 1);
    const professionalInsertResponse = await this.supabase.from('professional').insert([{
      pro_id: user.id,
      pro_email: email,
      pro_first_name: data.firstName,
      pro_last_name: data.lastName,
      pro_gender: data.gender,
      pro_age: data.age,
      pro_phone: data.phone,
      pro_street: data.street,
      pro_city_code: data.cityCode,
      pro_city: data.city,
      pro_country: data.country,
      pro_created_at: new Date().toISOString(),
      pro_start_subscription: pro_start_subscription.toISOString(),
      pro_end_subscription: pro_end_subscription.toISOString(),
      pro_subscription_type: 'trial_version',
      pro_subscription_status: 'active',
    }]);

    if (professionalInsertResponse.error) {
      throw professionalInsertResponse.error;
    }

    return professionalInsertResponse;
  }

  async signIn(credentials: { email; password }) {
    return this.supabase.auth.signInWithPassword(credentials);
  }

  sendPwReset(email) {
    return this.supabase.auth.resetPasswordForEmail(email);
  }

  async signOut() {
    await this.sendSubscriptionExpirationNotification(false);
    await this.supabase.auth.signOut();
    this.router.navigateByUrl('/', {replaceUrl: true});
  }

  getCurrentUser(): Observable<User | boolean> {
    return this.currentUser.asObservable().pipe(
      filter((user: any) => !!user),
      distinctUntilChanged()
    )
  }

  getOptionalCurrentUser(): Observable<User | boolean> {
    return this.currentUser.asObservable();
  }

  getUserId(): string | null {
    if (typeof this.currentUser === 'object' && this.currentUser && this.currentUser.value) {
      return this.currentUser.value['id'];
    }
    return null;
  }

  getUserEmail(): string | null {
    if (typeof this.currentUser === 'object' && this.currentUser && this.currentUser.value) {
      return this.currentUser.value['email'];
    }
    return null;
  }

  async setSession(access_token, refresh_token) {
    return this.supabase.auth.setSession({access_token, refresh_token});
  }

  async resetPassword(email: string, newPassword: string) {
    const {error} = await this.supabase.auth.updateUser({
                                                          email,
                                                          password: newPassword
                                                        });

    return {error};
  }

  hasRole(role: string): Observable<boolean> {
    return this.getCurrentUser().pipe(
      map(user => {
        return user && user['user_metadata'].role === role;
      })
    );
  }

  isAdmin(): Observable<boolean> {
    return this.getCurrentUser().pipe(
      map(user => {
        return user instanceof Object && user.email == "bileljallouli36@gmail.com";
      })
    );
  }

  getUserRole(): string | null {
    if (this.currentUser !== null && this.currentUser.value && this.currentUser.value) {
      return this.currentUser.value['user_metadata'].role;
    }
    return null;
  }

  isProfessional(): boolean {
    return this.getUserRole() === 'professional';
  }

  async isProSubscriptionExpired(): Promise<boolean> {
    const {data, error} = await this.supabase
                                    .from('professional')
                                    .select('pro_subscription_status')
                                    .eq('pro_id', this.getUserId())
                                    .single();

    if (error) {
      console.error('Error fetching professional subscription status:', error);
      return false;
    }
    return data?.pro_subscription_status === 'expired';
  }

  async checkAndNotifyExpiredSubscriptions() {
    const {data: data, error: fetchError} = await this.supabase
                                                      .from('professional')
                                                      .select('pro_id')
                                                      .eq('pro_id', this.getUserId())
                                                      .lte('pro_end_subscription', new Date().toISOString());
    if (fetchError) {
      console.error('Error fetching professional data:', fetchError);
      return;
    }

    const professionalId = data.length == 1 ? data[0].pro_id : null;
    if (!professionalId) {
      return;
    }

    const {error: updateError} = await this.supabase
                                           .from('professional')
                                           .update({pro_subscription_status: 'expired'})
                                           .eq('pro_id', professionalId);

    if (updateError) {
      console.error('Error updating subscription status:', updateError);
      return;
    }

    await this.sendSubscriptionExpirationNotification(true);
  }

  async sendSubscriptionExpirationNotification(isProSubscriptionExpired: boolean) {
    this.proExpirationNotificationSubject.next(isProSubscriptionExpired);
  }

  async renewUserSubscription() {
    // Récupérer la date de fin d'abonnement actuelle
    const { data, error: fetchError } = await this.supabase
                                                  .from('professional')
                                                  .select('pro_end_subscription')
                                                  .eq('pro_id', this.getUserId())
                                                  .single();

    if (fetchError) {
      console.error('Error fetching professional data:', fetchError);
      return;
    }

    // Utiliser la date de fin d'abonnement actuelle ou aujourd'hui si la date est passée
    let pro_start_subscription = new Date(data.pro_end_subscription);
    const currentDate = new Date();
    if (pro_start_subscription < currentDate) {
      pro_start_subscription = currentDate;
    }

    // Calculer la nouvelle date de fin d'abonnement (1 an + 2 jours)
    const pro_end_subscription = new Date(pro_start_subscription);
    pro_end_subscription.setFullYear(pro_end_subscription.getFullYear() + 1);
    pro_end_subscription.setDate(pro_end_subscription.getDate() + 2);
    pro_end_subscription.setHours(0, 0, 0, 0);

    // Mettre à jour l'abonnement de l'utilisateur
    const { error: updateError } = await this.supabase
                                             .from('professional')
                                             .update({
                                                       pro_start_subscription: pro_start_subscription.toISOString(),
                                                       pro_end_subscription: pro_end_subscription.toISOString(),
                                                       pro_subscription_status: 'active',
                                                       pro_subscription_type: 'one_year_license',
                                                     })
                                             .eq('pro_id', this.getUserId());

    if (updateError) {
      console.error('Error updating subscription status:', updateError);
      return;
    }
    await this.sendSubscriptionExpirationNotification(false);
    await this.router.navigateByUrl('/', { replaceUrl: true });
  }

  async getAllUserIds(): Promise<string[]> {
    const {data, error} = await this.supabase
                                    .from('user_details')
                                    .select('id');

    if (error) {
      console.error('Error fetching user IDs:', error);
      throw error;
    }

    return data.map((user: { id: string }) => user.id);
  }

  async isEmailExists(email: string): Promise<boolean> {
    const {data, error} = await this.supabase
                                    .from('user_details')
                                    .select('email')
                                    .eq('email', email);

    if (error) {
      console.error('Error checking email existence:', error);
      return false;
    }
    return data !== null && data.length > 0;
  }

  async isTermsAndConditionsAccepted(): Promise<boolean> {
    console.log(this.getUserId())
    try {
      const {data, error} = await this.supabase
                                      .from('cgu_agreements')
                                      .select('accepted')
                                      .eq('user_id', this.getUserId())
                                      .eq('accepted', true);

      if (error) {
        console.error('Erreur lors de la vérification des CGU :', error);
        return false;
      }

      return data.length > 0;
    } catch (err) {
      console.error('Erreur inattendue :', err);
      return false;
    }
  }
}
