import { Pipe, PipeTransform } from '@angular/core';
import {DateUtils} from "./DateUtils";

@Pipe({
        name: 'utcToLocal'
      })
export class UtcToLocalPipe implements PipeTransform {

  transform(utcDate: string): Date {
    return DateUtils.convertUTCToLocalDate(utcDate);
  }

}
