import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {AuthService} from "../../auth/auth.service";

@Injectable({
              providedIn: 'root',
            })
export class AdminGuard  {
  constructor(private auth: AuthService, private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.auth.isAdmin().pipe(
      take(1),
      map(isAdmin => {
        if (isAdmin) {
          return true;
        }
        else {
          return this.router.createUrlTree(['/']);
        }
      })
    );
  }
}
