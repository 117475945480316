import {AuthService} from '../auth.service';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, UrlTree} from '@angular/router';
import {from, Observable, of, switchMap} from 'rxjs';
import {map, take} from 'rxjs/operators';

@Injectable({
              providedIn: 'root',
            })
export class AuthGuard  {
  constructor(private auth: AuthService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.auth.getCurrentUser().pipe(
      take(1),
      switchMap(isAuthenticated => {
        if (!isAuthenticated) {
          return of(this.router.createUrlTree(['/']));
        }

        // Authenticated, now check terms and conditions
        return from(this.auth.isTermsAndConditionsAccepted()).pipe(
          take(1),
          map(accepted => {
            if (accepted) {
              return true;
            } else {
              return this.router.createUrlTree(['/terms-and-conditions']);
            }
          })
        );
      })
    );
  }
}
