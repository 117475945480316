export const environment = {
  production: true,
  supabaseUrl: 'https://wvwkdtxwexkyodeugotl.supabase.co',
  supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Ind2d2tkdHh3ZXhreW9kZXVnb3RsIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTkxNzYyNjEsImV4cCI6MjAzNDc1MjI2MX0.Cn5cBGopePuKbkcL0RWkMdb5hn8zN8E6W2az1IHPLm4',
  smtpEndpoint: 'https://api.brevo.com/v3/smtp/email',
  smtpApiKey: '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',
  emailSender: 'support@football-ground.com',
  // Cet Email reçoit les emails de concatez-nous
  emailReceiver: 'bileljallouli11@hotmail.fr',
  stripeServerUrl: 'https://football-ground.com',
  stripePublicKey: 'pk_live_51NMTapG5cbewLmgiStf3aB1h71LR6OTZR72u2p2GcPbs4DzQrn967xKhE2OpWrG9bCmJzEpJWJ2A8UruZY5YB75v007kuDjosH'
};
