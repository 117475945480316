import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { UtcToLocalPipe } from './utils/utc-to-loca-pipe';
import { provideHttpClient } from '@angular/common/http';

@NgModule({
            declarations: [AppComponent, UtcToLocalPipe],
            imports: [BrowserModule, IonicModule.forRoot({ innerHTMLTemplatesEnabled: true }), AppRoutingModule],
            providers: [provideHttpClient(), { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
            bootstrap: [AppComponent]
          })
export class AppModule {
}
