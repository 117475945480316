// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unread-notification {
  background-color: #e7f3ff;
  font-weight: bold;
}

ion-item.unread-notification {
  --background: #e0f7fa;
}

ion-item {
  cursor: pointer;
}

.no-notifications-message p {
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
  color: #666;
  font-weight: 500;
  line-height: 1.5;
  padding: 10px 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/utils/notification-popover/notification-popover.page.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,iBAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,WAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;AACF","sourcesContent":[".unread-notification {\n  background-color: #e7f3ff;\n  font-weight: bold;\n}\n\nion-item.unread-notification {\n  --background: #e0f7fa;\n}\n\nion-item{\n  cursor: pointer;\n}\n\n.no-notifications-message p {\n  text-align: center;\n  margin-top: 20px;\n  font-size: 16px;\n  color: #666;\n  font-weight: 500;\n  line-height: 1.5;\n  padding: 10px 15px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
