import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
              providedIn: 'root'
            })
export class CookieConsentGuard implements CanActivate {
  constructor(private cookieService: CookieService, private router: Router) {}

  canActivate(): boolean {
    const consent = this.cookieService.get('cookie_consent');

    if (consent) {
      return true;
    } else {
      this.router.navigate(['/cookie-consent']);
      return false;
    }
  }
}
