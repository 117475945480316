import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
              providedIn: 'root',
            })
export class CookieConsentService {
  private cookieConsentSubject = new BehaviorSubject<string | null>(null);
  public cookieConsent$ = this.cookieConsentSubject.asObservable();

  constructor(private cookieService: CookieService) {
    const consent = this.cookieService.get('cookie_consent');
    if (consent) {
      this.cookieConsentSubject.next(consent);
    } else {
      this.cookieConsentSubject.next(null);
    }
  }

  acceptCookies() {
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 10);
    this.cookieService.set('cookie_consent', 'accepted', expirationDate);
    this.cookieConsentSubject.next('accepted');
  }

  declineCookies() {
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 10);
    this.cookieService.set('cookie_consent', 'declined', expirationDate);
    this.cookieConsentSubject.next('declined');
  }
}
